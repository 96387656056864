<template>
    <div class="p-1">
        <b-overlay
            variant="white"
            :show="showLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
        >
            <div class="row">
                <div class="form-group col-12 col-md-4">
                    <label for="codeAcre">Código Acreedor:</label>
                    <b-form-input v-model="provider.code" autocomplete="off"/>
                    <span v-if="validation_provider.code"
                          class="text-danger">{{ validation_provider.code }}</span>
                </div>
                <div class="form-group col-12 col-md-4">
                    <label for="codeSAGA">Código SAGA:</label>
                    <b-form-input v-model="provider.saga_code" autocomplete="off"/>
                    <span v-if="validation_provider.saga_code"
                          class="text-danger">{{ validation_provider.saga_code }}</span>
                </div>
                <div class="form-group col-12 col-md-1 d-flex form-check form-check-inline mt-2" >
                    <b-form-group class="form-check form-check-inline">
                        <b-form-checkbox v-model="provider.unsubscribe" :value="1">
                            Baja
                        </b-form-checkbox>
                    </b-form-group>
                    <span v-if="validation_provider.unsubscribe"
                          class="text-danger">{{ validation_provider.unsubscribe }}</span>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12 col-md-7">
                    <label for="name">Nombre:</label>
                    <b-form-input v-model="provider.provider" autocomplete="off"/>
                    <span v-if="validation_provider.provider"
                          class="text-danger">{{ validation_provider.provider }}</span>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12 col-md-4">
                    <label for="cif">CIF:</label>
                    <b-form-input v-model="provider.cif" autocomplete="off"/>
                    <span v-if="validation_provider.cif"
                          class="text-danger">{{ validation_provider.cif }}</span>
                </div>
                <div class="form-group col-12 col-md-4">
                    <label for="country">Tipo Proveedor:</label>
                    <select id="country" v-model="provider.provider_type_id" class="form-control">
                        <option v-for="(providerType, key) in providersType" :key="key"
                                :value="key">{{ providerType }}
                        </option>
                    </select>
                    <span v-if="validation_provider.provider_type_id"
                          class="text-danger">{{ validation_provider.provider_type_id }}</span>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12 col-md-4">
                    <label for="pobl">Población:</label>
                    <b-form-input v-model="provider.city" autocomplete="off"/>
                    <span v-if="validation_provider.city"
                          class="text-danger">{{ validation_provider.city }}</span>
                </div>
                <div class="form-group col-12 col-md-4">
                    <label for="postalcode">Código Postal:</label>
                    <b-form-input v-model="provider.postal_code" autocomplete="off"/>
                    <span v-if="validation_provider.postal_code"
                          class="text-danger">{{ validation_provider.postal_code }}</span>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12 col-md-4">
                    <label for="country">País:</label>
                    <select id="country" v-model="country" class="form-control"
                            @change="getProvince">
                        <option v-for="country in countries" :key="country.country_id"
                                :value="country.country_id">{{ country.country }}
                        </option>
                    </select>
                    <span v-if="validation_provider.country"
                          class="text-danger">{{ validation_provider.country }}</span>
                </div>
                <div class="form-group col-12 col-md-4">
                    <label for="prov">Provincia:</label>
                    <select id="prov" v-model="provider.province_id" class="form-control"
                            :disabled="!provinceInputActive">
                        <option v-for="province in provinces" :key="province.id"
                                :value="province.id">{{ province.province }}
                        </option>
                    </select>
                    <span v-if="validation_provider.province_id"
                          class="text-danger">{{ validation_provider.province_id }}</span>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12 col-md-7">
                    <label for="dir">Dirección:</label>
                    <b-form-textarea v-model="provider.address" autocomplete="off"/>
                    <span v-if="validation_provider.address"
                          class="text-danger">{{ validation_provider.address }}</span>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-12 col-md-4">
                    <label for="expgest">Exp. Gestiona:</label>
                    <b-form-input v-model="provider.document_order_number" autocomplete="off"/>
                    <span v-if="validation_provider.document_order_number" class="text-danger">
                                        {{ validation_provider.document_order_number }}
                                    </span>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-12 col-md-7">
                    <label for="obsv">Observaciones:</label>
                    <b-form-textarea v-model="provider.observations" autocomplete="off"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12 col-md-3">
                    <label for="email">Email:</label>
                    <b-form-input v-model="provider.email" autocomplete="off"/>
                    <span v-if="validation_provider.email"
                          class="text-danger">{{ validation_provider.email }}</span>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12 col-md-3">
                    <label for="phone24">Tel 24h:</label>
                    <b-form-input v-model="provider.phone_24" autocomplete="off"/>
                    <span v-if="validation_provider.phone_24"
                          class="text-danger">{{ validation_provider.phone_24 }}</span>
                </div>
            </div>
            <div class="row">
                <b-button v-if="!edit" variant="primary" class="mt-2 mr-1 ml-2" @click="createInstallation">
                    Guardar
                </b-button>
                <b-button v-else variant="primary" class="mt-2 mr-1 ml-2" @click="updateInstallation">
                    Editar
                </b-button>

                <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                    Cancelar
                </b-button>
            </div>
        </b-overlay>
    </div>
</template>

<script>
import {BFormTextarea, BFormInput, BFormCheckbox, BFormGroup, BButton, BOverlay} from 'bootstrap-vue'
import {mapState} from "vuex"
import {GEI_AUXILIAR_PROVIDERS_LIST} from "@/router/web/constants"
import {createHelpers} from "vuex-map-fields"

import helper from "@/resources/helpers/helpers"

const {mapFields} = createHelpers({
    getterType: 'auxiliariesProvider/getField',
    mutationType: 'auxiliariesProvider/updateField',
})

export default {
    name: "FormView",
    components: {
        BOverlay,
        BButton,
        BFormInput,
        BFormCheckbox,
        BFormTextarea,
        BFormGroup,
    },
    data() {
        return {
            edit: false,
            showLoading: false,
            country: 0,
            province: '',
            countries: [],
            provinces: [],
            providersType: {
                1: 'Mantenedor',
                2: 'Subcontrata',
                3: 'Transporte',
                4: 'PRL',
            },
            provinceInputActive: false,
        }
    },
    computed: {
        ...mapState('providers', ['validation_provider']),
        ...mapFields({
            provider: 'provider',
        }),
    },
    created() {
        this.$store.commit('auxiliariesProvider/RESET_STATE')
        this.$store.commit('auxiliariesProvider/CLEAR_VALIDATION')
        this.getCountry()
        if (this.$route.params.id) {
            this.edit = true
            this.getProviders()
        } else {
            this.edit = false
        }
        this.$root.$refs.auxiliariesProviderForm = this
    },
    methods: {
        getProviders() {
            this.showLoading = true
            this.$store.dispatch('providers/getProvider', {
                id: this.$route.params.id ?? this.provider.id,
                relations: ['province']
            })
                .then(response => {
                    this.country = response.province.country_id
                    this.provider.province_id = response.province_id
                    this.getCountry()
                    this.getProvince()
                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        cancel() {
            this.$router.push(GEI_AUXILIAR_PROVIDERS_LIST)
        },
        createInstallation() {
            this.$store.dispatch('auxiliariesProvider/createProviders', this.provider)
                .then(response => {
                    helper.showToast('Proveedor creado correctamente!', 'CheckIcon', 'success')
                    this.$router.replace(GEI_AUXILIAR_PROVIDERS_LIST)
                })
                .catch(error => {
                    helper.showToast('Error al crear proveedor', 'AlertOctagonIcon', 'danger')
                    console.log(error)
                })
        },
        getCountry() {
            this.showLoading = true
            this.$store.dispatch('auxiliariesProvider/getCountry')
                .then(response => {
                    this.countries = response
                    this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        getProvince() {
            this.$store.dispatch('auxiliariesProvider/getProvince', this.country)
                .then(response => {
                    this.provinces = response
                    this.showLoading = false
                    this.provinceInputActive = true
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        updateInstallation() {
            this.$store.dispatch('auxiliariesProvider/updateProviders', this.provider)
                .then(response => {
                    helper.showToast('Proveedor editado correctamente!', 'CheckIcon', 'success')
                    this.$router.replace(GEI_AUXILIAR_PROVIDERS_LIST)
                })
                .catch(error => {
                    helper.showToast('Error al editar proveedor', 'AlertOctagonIcon', 'danger')
                    console.log(error)
                })
        },
    },
}
</script>
