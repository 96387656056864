<template>
    <div class="p-1">
        <b-overlay
            variant="white"
            :show="showLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
        >
            <b-row>
                <b-col cols="6" lg="3" md="4" sm="6">
                    <b-form-group label="Nombre" label-for="name">
                        <b-form-input id="name" v-model="contact.name" placeholder="Nombre" autocomplete="off"/>
                        <small v-if="validation_contact.name" class="text-danger">{{ validation_contact.name }}</small>
                    </b-form-group>
                </b-col>

                <b-col cols="6" lg="3" md="4" sm="6">
                    <b-form-group label="Apellidos" label-for="surnames">
                        <b-form-input id="surnames" v-model="contact.surnames" placeholder="Apellidos" autocomplete="off"/>
                        <small v-if="validation_contact.surnames" class="text-danger">{{ validation_contact.surnames }}</small>
                    </b-form-group>
                </b-col>


                <b-col cols="6" lg="3" md="4" sm="6">
                    <b-form-group label="Cargo" label-for="job_type">
                        <b-form-input id="job_type" v-model="contact.job_type" placeholder="Cargo" autocomplete="off"/>
                        <small v-if="validation_contact.job_type" class="text-danger">{{ validation_contact.job_type }}</small>
                    </b-form-group>
                </b-col>


                <b-col cols="6" lg="3" md="4" sm="6">
                    <b-form-group label="Teléfono 1" label-for="phone_1">
                        <b-form-input id="phone_1" v-model="contact.phone_1" placeholder="Teléfono 1" autocomplete="off"/>
                        <small v-if="validation_contact.phone_1" class="text-danger">{{ validation_contact.phone_1 }}</small>
                    </b-form-group>
                </b-col>


                <b-col cols="6" lg="3" md="4" sm="6">
                    <b-form-group label="Teléfono 2" label-for="phone_2">
                        <b-form-input id="phone_2" v-model="contact.phone_2" placeholder="Teléfono 2" autocomplete="off"/>
                        <small v-if="validation_contact.phone_2" class="text-danger">{{ validation_contact.phone_2 }}</small>
                    </b-form-group>
                </b-col>


                <b-col cols="6" lg="3" md="4" sm="6">
                    <b-form-group label="Email" label-for="email">
                        <b-form-input id="email" v-model="contact.email" placeholder="Email" autocomplete="off"/>
                        <small v-if="validation_contact.email" class="text-danger">{{ validation_contact.email }}</small>
                    </b-form-group>
                </b-col>


                <b-col cols="6" lg="2" md="4" sm="6">
                    <b-form-group label="" label-for="management_contact">
                        <b-form-checkbox v-model="contact.management_contact" :value="1" class="mt-2">
                            Contacto Gestiona
                        </b-form-checkbox>
                        <small v-if="validation_contact.management_contact" class="text-danger">{{ validation_contact.management_contact }}</small>
                    </b-form-group>
                </b-col>

                <b-col cols="6" lg="2" md="4" sm="6">
                    <b-form-group label="" label-for="management_contact">
                        <b-form-checkbox v-model="contact.management_cat" :value="1" class="mt-2">
                            Contacto CAT
                        </b-form-checkbox>
                        <small v-if="validation_contact.management_cat" class="text-danger">{{ validation_contact.management_cat }}</small>
                    </b-form-group>
                </b-col>

                <b-col cols="6" lg="2" md="4" sm="6">
                    <b-button v-if="contact.id==null" variant="primary" class="mt-2 mr-1 ml-2" @click="createContact">
                        Guardar
                    </b-button>

                    <b-button v-else variant="primary" class="mt-2 mr-1 ml-2" @click="updateContact">
                        Editar
                    </b-button>
                </b-col>
            </b-row>
        </b-overlay>

        <b-table
            class="mt-2"
            responsive="sm"
            :items="contacts"
            :fields="columns"
        >
            <template #cell(management_contact)="data" class="col-sm-2">
                <b-form-checkbox v-model="data.item.management_contact" :value="1" class="mt-2" disabled />
            </template>

            <template #cell(management_cat)="data" class="col-sm-2">
                <b-form-checkbox v-model="data.item.management_cat" :value="1" class="mt-2" disabled />
            </template>

            <template #cell(actions)="data" class="col-sm-2">
                <feather-icon
                    :id="`invoice-row-${data.item.id}-send-icon`"
                    icon="EditIcon"
                    class="cursor-pointer text-blue"
                    size="16"
                    @click="editContact(data.item)"
                />

                <feather-icon
                    :id="`invoice-row-${data.item.id}-preview-icon`"
                    icon="TrashIcon"
                    size="16"
                    class="cursor-pointer ml-1 text-red"
                    @click="deleteContact(data.item.id)"
                />
            </template>
        </b-table>
    </div>
</template>

<script>
import {BRow, BCol, BFormInput, BFormCheckbox, BFormGroup, BButton, BOverlay} from 'bootstrap-vue'
import {mapState} from "vuex"
import { BTable } from 'bootstrap-vue'

import {createHelpers} from "vuex-map-fields"
import helper from "@/resources/helpers/helpers"
import Swal from "sweetalert2"

const {mapFields} = createHelpers({
    getterType: 'auxiliariesProvider/getField',
    mutationType: 'auxiliariesProvider/updateField',
})

export default {
    name: "ContactsFormView",
    components: {
        BTable,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormCheckbox,
        BFormInput,
        BOverlay
    },
    data() {
        return {
            showLoading: false,
            columns: [
                {key: 'name', label: 'Nombre', sortable: false},
                {key: 'surnames', label: 'Apellidos', sortable: false},
                {key: 'job_type', label: 'Cargo', sortable: false},
                {key: 'phone_1', label: 'Teléfono1', sortable: false},
                {key: 'phone_2', label: 'Teléfono2', sortable: false},
                {key: 'email', label: 'Email', sortable: false},
                {key: 'management_contact', label: 'Contacto Gestiona', sortable: false},
                {key: 'management_cat', label: 'Contacto CAT', sortable: false},
                {key: 'actions', label: 'Acciones', sortable: false},
            ],
            contacts: [],
        }
    },
    computed: {
        ...mapState('providers', ['validation_contact']),
        ...mapFields({
            provider: 'provider',
            contact: 'contact',
        }),
    },
    created() {
        this.getContactsProviders()
    },
    methods: {
        getContactsProviders() {
            this.showLoading = true

            this.$store.dispatch('providers/getContactsProviders', {
                id: this.$route.params.id,
            })
                .then(response => {
                    this.showLoading = false

                    this.contacts = response
                })
                .catch(error => {
                    this.showLoading = false
                    console.log(error)
                })
        },
        createContact(){
            this.showLoading = true
            this.contact.provider_id = this.$route.params.id

            this.$store.dispatch('providers/createContactProvider', {
                contact: this.contact,
            })
                .then(response => {
                    this.showLoading = false

                    helper.showToast('Contacto creado correctamente!', 'CheckIcon', 'success')
                    this.$store.commit('providers/RESET_STATE_CONTACT')
                    this.getContactsProviders()
                })
                .catch(error => {
                    this.showLoading = false
                    console.log(error)
                })
        },
        editContact(contact){
            this.$store.commit('providers/CLEAR_VALIDATION_CONTACT')
            for (const [key, value] of Object.entries(contact)) {
                this.contact[key] = value
            }
        },
        updateContact(){
            this.showLoading = true
            this.contact.provider_id = this.$route.params.id

            this.$store.dispatch('providers/updateContactProvider', {
                contact: this.contact,
            })
                .then(response => {
                    this.showLoading = false

                    helper.showToast('Contacto editado correctamente!', 'CheckIcon', 'success')
                    this.$store.commit('providers/RESET_STATE_CONTACT')
                    this.getContactsProviders()
                })
                .catch(error => {
                    this.showLoading = false
                    console.log(error)
                })
        },
        deleteContact(id){
            Swal.fire({
                title: "Estás seguro que quieres eliminar el contacto?",
                text: "Una vez borrado no se podra recuperar!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sí, borrar!",
                cancelButtonText: "Cancelar",
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('providers/deleteContactProvider', {id: id})
                        .then(response => {
                            this.showLoading = false
                            helper.showToast('Contacto eliminado correctamente!', 'CheckIcon', 'success')
                            this.getContactsProviders()
                        })
                        .catch(error => {
                            this.showLoading = false
                            helper.showToast('Error al eliminar el contacto', 'AlertOctagonIcon', 'danger')
                            console.log(error)
                        })
                }
            })
        }
    },
}
</script>
