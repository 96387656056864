<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-tabs>
                    <b-tab active title="Datos Proveedor">
                        <main-form />
                    </b-tab>
                    <b-tab v-if="edit" title="Contactos">
                        <contacts-form />
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { BCard, BCol, BOverlay, BRow, BTabs, BTab } from 'bootstrap-vue'
import {mapState} from "vuex"
import {createHelpers} from "vuex-map-fields"
import mainForm from "@/views/gei/auxiliars_providers/form/components/mainFormView"
import contactsForm from "@/views/gei/auxiliars_providers/form/components/contactsForm"

const {mapFields} = createHelpers({
    getterType: 'auxiliariesProvider/getField',
    mutationType: 'auxiliariesProvider/updateField',
})

export default {
    name: "FormView",
    components: {
        BTab,
        BTabs,
        BCol,
        BRow,
        BCard,

        mainForm,
        contactsForm,
    },
    data() {
        return {
            edit: false,
        }
    },
    computed: {
        ...mapState('providers', ['validation_provider']),
        ...mapFields({
            provider: 'provider',
        }),
    },
    created() {
        this.edit = !!this.$route.params.id
    },
    methods: {
    },
}
</script>
